import React, { useState,useEffect } from "react";
import ScrollButton from "../../../components/scrollbutton/ScrollButton";
import Subheader from "../../../components/subheader/Subheader";
import { Footer } from "../../../components/footer/footer";
import { ReactComponent as YourIcon } from "../../../assets/mdi_calendar.svg";
import { ReactComponent as YourIcon1 } from "../../../assets/time.svg";
import "../../dashboard/Dashboard.css";
import firstitem from "../../../assets/dummy-movie.png";
import Navbarr from "../../../components/header/Navbarr";
import {inviteleagueGet} from "../../../api/handleapi";
import {cookieNames, imgpath} from "../../../Config/config"
import CountDownTimer from "../../../components/countdowntimer/CountDownTimer";
import Pagination from "../../../components/pagination/Pagination";
import {joinleaguebtnPost} from "../../../api/handleapi"
import swal from "sweetalert";
import { Errormsg } from "../../../components/tostifymsg/Errrormsg";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { alreadyjoinMsg, errorstatuscheck, reloginMsg, successfullyJoinedMsg, successfullyTitle } from "../../../components/language/Language";
import { handle401Error } from "../../../components/handle401Error";
import Googleadshorizontal from "../../../components/google-AdSense/Googleadshorizontal";


function Myleagueinvites() {
const id=Cookies.get("id")
const [inviteget,setInviteget]=useState([]);
const [pageN, setPageN] = useState(1);
const itemsPerPage = 4; // Replace with your actual items per page
const totalPages = Math.ceil(inviteget?.length / itemsPerPage);
const reversedData = inviteget?.slice().reverse(); // Create a copy of the array and reverse it
const currentPageData = reversedData?.slice((pageN- 1) * itemsPerPage, pageN * itemsPerPage);
const [hiddenButtons, setHiddenButtons] = useState({});
const Navigate=useNavigate();
const [fetchdata , setFetchData] = useState(false);

  useEffect(() => {
    const inviteleagueFun = async (id) => {
      try {
        const response = await inviteleagueGet(id); // You might want to pass some parameters to invites leauges get
        setInviteget(response?.data?.data);
      } catch (error) {
        if(error?.message==errorstatuscheck){         
          await Errormsg(reloginMsg);
          await handle401Error(cookieNames)
          await Navigate("/");
        }
      }
    };
    inviteleagueFun(id);
      }, [fetchdata]);

const joinlPost = async (LeagueId,leagueName) => {
  var bodyFormData = new FormData();
  bodyFormData.append("UserId", id);
  bodyFormData.append("LeagueId", LeagueId );
  try{
    const response = await joinleaguebtnPost(bodyFormData)
        setFetchData(!fetchdata)
        setHiddenButtons(prevState => ({ ...prevState, [LeagueId]: true }));
      
        swal({
          title: successfullyTitle,
          text: `${successfullyJoinedMsg} ${leagueName}`,
          icon: "success",
          buttons: "ok",
          closeOnClickOutside:false,
          dangerMode: false
        })
        
  }
    catch(error){
      swal({
        title: "",
        text: `${alreadyjoinMsg} ${leagueName}`,
        icon: "error",
        buttons: "ok",
        closeOnClickOutside:false,
        dangerMode: false
      })
  }
  
};

const handlePageClick = ({ selected }) => {
  setPageN(selected+1);
};


  return (
    <div>
      <Navbarr/>
      <div className="after-nav-dsubheader containers con_div">
      <Googleadshorizontal props="pt-2 pb-2 text-center"/>
        <Subheader />
        <div className="Dasboard-cards">
          <div className="afterdashboard_nav">
          {currentPageData?.length > 0 ? 
          currentPageData?.map((data, index) => (
        
          <div className="card d-Cardf" key={index}>
            <div className="after_row_div">
              <div className="row">
                <div className="col-xl-1 col-lg-3 col-md-12 col-sm-12 imag-cardclass">
                  <img src={imgpath + data?.image} alt="img" className="dleague-card-img"  onError={(e) => {
    e.target.onerror = null;
    e.target.src = firstitem }} />
                </div>
                <div className="col-xl-11 col-lg-10 col-md-12 col-sm-12 league-card-content">
                  <div>
                    <p className="card-title">{data?.leagueName}</p>
                    <div className="tags">
                      <span className="btn btn-primary PP-btn ">
                   {data?.visibility}
                      </span>
                      <a href="##" className="btn btn-primary PP-btn ">
                        Invites
                      </a>
                    </div>
                  </div>
                  <div>
                    <p className="card-title upcoming_time_class">
                      <CountDownTimer key={index} createdatetime={new Date(data?.createdDate)} startdatetime={new Date(data?.startDateTime)} />
                    </p>
                  </div>
                  <div>
                    <p className="card-title">
                      {data?.templateName}
                      </p>
                    <p className="card-title"> No of Players: {data?.totalPlayers}/{data?.entries}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="dleague-card-hr" />
            <nav className="dleague-card-nav">
              <span className="dleague-card-nav-span">
                <div className="time_with_date">
                <div className="cal-time">
                  {" "}
                  <YourIcon />
                  <span className="data m-1"> 
                  { moment(new Date(data?.createdDate + 'Z')).format("dddd, MMMM D, YYYY")}
                     
                     </span>
                </div>
                <div className="timeicon-div">
                  <YourIcon1 />
                  <span className="time m-1"> 
                  { moment(new Date(data?.createdDate + 'Z')).format("h:mm a")}
                     
                      </span>
                </div>
                </div>
                
                {!hiddenButtons[data.id] &&<div>
                  <button className="btn btn-primary JL-btn " onClick={()=>joinlPost(data?.id,data?.leagueName)} disabled ={data?.totalPlayers>=data?.entries?true:false}>
                    {data?.totalPlayers>=data?.entries?"Entry Full":"Join League"}
                  </button>
                </div>}
              </span>
            </nav>
          </div>)):<div> <div className="no_league_found" >No data found</div>
                  </div>}
          </div>
          {inviteget?.length==0?"":<div>
              <Pagination pageCount={totalPages} handlePageClick={handlePageClick} />
          </div>}
        </div>
      </div>
      <Footer />
      <ScrollButton />
    </div>
  );
}

export default Myleagueinvites;
