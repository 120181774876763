import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import React, { useState,useEffect  } from "react";
import { ReactComponent as YourIcon } from "../../assets/ei_user.svg";
import * as signalR from '@microsoft/signalr';
import { Link } from "react-router-dom";
import logo from "../../assets/Gaffer Logo Transparent2-03-ai (2).png";
import "./Navbar.css";
import {ModalTrading} from "../../pages/myleague/live/viewdetails/trade/ModalTrading";
import { ReactComponent as YourIconarrow } from "../../assets/keyboard_arrow_down.svg";
import { ReactComponent as YourIconn } from "../../assets/notifications.svg";
import profileimg from "../../assets/large profileicon.svg";
import { cookieNames, imgpath, REACTAPPSignalRPREFIX } from "../../Config/config";
import {  useDispatch } from 'react-redux';
import { openModal ,closeModal} from '../../feature/showSlice/showModalSlice';
import { useNavigate } from "react-router-dom";
import {notificationGet,trademoviesGet,confirmemailGet,WatchlistmoviesGet,newNewsandarticleget,PlayerdetailsGet, refeshTokenapi, leagueinvitenotificationGet, readtradeMsg, readleagueInvite} from "../../api/handleapi";
import { useSelector } from "react-redux";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation } from 'react-router-dom';
import { Errormsg } from "../tostifymsg/Errrormsg";
import { setWatchlistcount } from '../../feature/showSlice/watchlistcountSlice';
import Cookies from 'js-cookie';
import { ModalTradeMoviedetails } from "../../pages/myleague/live/viewdetails/trade/ModalTradeMoviedetails";
import Reuseformodal from "../modalc/Reuseformodal";
import {  emailconfirmtokenAuthcheck, errorstatuscheck, errorstatuscheck400, newsnotificationregexcheck, reloginMsg, tokenAuthcheck, tradereadconditonMSG } from "../language/Language";
import { handle401Error } from "../handle401Error";

function Navbarr() {

  const location = useLocation();
  const Navigate=useNavigate();
  const [toggleform,setToggleform]=useState(false);
  const [openforgot,setOpenforgot]=useState(false);
  const [watchlistlength,setWatchlistlength]=useState([]);
  const watchlistcount = useSelector((state) => state.watchlistcount);
  const [showotp,setShowotp]=useState(null)

  const userprofileimage=Cookies.get("userprofileimage")
  const name=Cookies.get("name")
  const userid=Cookies.get("id")
  const loginuserinfo = useSelector((state) => state.loginuserinfo);
  const [showModalTrading, setShowModalTrading] = useState(false);
  const [showtradeaccpet, setShowTradeaccpet] = useState(false);
  const [notificationget, setNotificationget] = useState([]);
  const [notificationlength, setNotificationlength] = useState(0);

  const [trademovies, setTrademovies] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);   
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [playername,setplayername]=useState();
  const [hubConnectionnotificationbell, setHubConnectionnotificationbell] = useState(null);
  const [hubConnectionnotification, setHubConnectionnotification] = useState(null);
  const isActiveformovies = location.pathname == '/Movieslist' || location.pathname.startsWith('/Moviedetails');
  const isActivefornews = location.pathname == '/Newslisting' || location.pathname.startsWith('/Newsdetails');
  const token=Cookies.get('token');


  const readtradeMessage = async(userid,msgid,msg) => {
    try {
      const response =  await readtradeMsg(
        userid,msgid,msg
        );
      if (response?.status === 200) {
          if(response?.data?.message==tradereadconditonMSG){ 
            notificationGetcallforread(userid)
         
     }
       else{
        return;
       }
        }       
      }
    catch (error) {
     return;
    }
  };

  const readleagueinviteMessage = async(msgid) => {
    try {
      const response =  await readleagueInvite(
        msgid
        );
      if (response?.status === 200) {
        leagueinvitecall(userid)
        }       
      }
    catch (error) {
     return;
    }
  };

  const newNewsandarticlesget = async (msg) => {
    try {  
      const response = await newNewsandarticleget(msg); // You might want to pass some parameters to news and articles get
      Navigate(`/Newsdetails/${response?.data?.data[0]?.id}`);
    } catch (error) {
      return;
    }
  };


  const openModalTrading = (msg,msgid) => {
    const regex = newsnotificationregexcheck;
      const match = new RegExp(regex).exec(msg);
    if(match){
      newNewsandarticlesget(`New article/news has been released with title: ${match[1]}`);
    }
    else{
      if (msg?.includes("wants")){
        Cookies.set("trademsg",msg)
        readtradeMessage(userid,msgid,msg)
        trademovieGet(userid,msg)
      setShowModalTrading((showModalTrading) => !showModalTrading);
    }
   
    else if(msg?.includes("accepted")){
      Cookies.set("trademsg",msg)
      readtradeMessage(userid,msgid,msg)
      trademovieGet(userid,msg)
      setShowTradeaccpet(true);
    }
    else if(msg?.includes("invited")){
      readleagueinviteMessage(msgid)
      Navigate("/Myleagueinvites")
    }

    else if(msg?.includes("has")){
      readtradeMessage(userid,msgid,msg)
    }
  }
  };

 
  const handleOpen = () => {
    dispatch(openModal());
  };

   const logOut = () => {
     Cookies.remove('token');
      Cookies.remove('rrefreshToken');
     Cookies.remove('email');
     Cookies.remove('name');
     Cookies.remove('id');
     Cookies.remove('username');
     Cookies.remove('userprofileimage');
     Cookies.remove('Paymentdetail');
     Cookies.remove("noofjoinplayers");
     Cookies.remove("secondpid")
     Cookies.remove("secondpusername")
     Cookies.remove("navivalue")
     Cookies.remove("trademsg")
     Cookies.remove("tradeaccept")
     Cookies.remove("cleagueid")
     localStorage.clear()
      navigate("/")
     dispatch(closeModal());
   };

useEffect(() => {
if(Cookies.get("trademsg")){
  if (Cookies.get("trademsg")?.includes("wants") && Cookies.get("tradeaccept")!=null||undefined ){
    trademovieGet(userid,Cookies.get("trademsg"))
    setShowTradeaccpet(true);
}
else if(Cookies.get("trademsg")?.includes("accepted")){
  trademovieGet(userid,Cookies.get("trademsg"))
  setShowTradeaccpet(true);
}
else{
  return;
}
}
}, [Cookies.get("trademsg")])

const notificationGetcallforread = async (userid) => {
  try {
    const response = await notificationGet(userid); // You might want to pass some parameters to notificationget
    const notifications = response?.data?.data==undefined||null?[]:response?.data?.data;
    
        setNotificationget(() => {
      // Combine new notifications with previous notifications
      const combinedNotifications = [...notifications];
      // Use a Set to filter out duplicates
      const uniqueNotifications = [...new Set(combinedNotifications?.map(JSON.stringify))]?.map(JSON.parse);
     
    const  uniqueNotificationsreadlength = uniqueNotifications?.filter((notification) => !notification?.isViewed)?.length;
     
      setNotificationlength(uniqueNotificationsreadlength);
      return uniqueNotifications;
    });
  } catch (error) {
   return;
  }
};



   const notificationGetcall = async (userid) => {
    try {
      const response = await notificationGet(userid); // You might want to pass some parameters to notificationget
      const notifications = response?.data?.data==undefined||null?[]:response?.data?.data;
      
          setNotificationget((prevNotifications) => {
        // Combine new notifications with previous notifications
        const combinedNotifications = [...notifications, ...prevNotifications];
        // Use a Set to filter out duplicates
        const uniqueNotifications = [...new Set(combinedNotifications?.map(JSON.stringify))]?.map(JSON.parse);
       
      const  uniqueNotificationsreadlength = uniqueNotifications?.filter((notification) => !notification?.isViewed)?.length;
       
        setNotificationlength(uniqueNotificationsreadlength);
        return uniqueNotifications;
      });
    } catch (error) {
     return;
    }
  };

  const leagueinvitecall = async (userid) => {
    try {
      const response = await leagueinvitenotificationGet(userid); // You might want to pass some parameters to notificationget league invite
           if(response?.data?.message==="List of League Invites" && response?.data?.data?.length > 0){
            const newMessage = response?.data?.data==undefined||null?[]:response?.data?.data?.reverse();
          setNotificationget((prevNotifications) => {
            
              const combinedNotifications = [...newMessage,...prevNotifications];
             
              const uniqueNotifications = [...new Set(combinedNotifications?.map(JSON.stringify))]?.map(JSON.parse);
              const  uniqueNotificationsreadlength = uniqueNotifications?.filter((notification) => !notification?.isViewed && !notification?.isRead)?.length;
              setNotificationlength(uniqueNotificationsreadlength);
              return uniqueNotifications;
            });
           }
    
    } catch (error) {
     return;
    }
  };
 

  useEffect(() => {
    if(userid){
      notificationGetcall(userid);
      leagueinvitecall(userid);
    }
       },[]);

       
    
    
      const trademovieGet = async (userid,msg) => {
        try {
          const response = await trademoviesGet(userid,msg); // You might want to pass some parameters to both user trademovies  get
          setTrademovies(response?.data?.data);
          PlayerdetailsGetname(response.data?.data?.requestFrom?.firstUserId)

        } catch (error) {
         return;
        }
      };


      const PlayerdetailsGetname = async (playerid) => { // get the playerdetails for show the name for trade request how sent
        try {
          const response = await PlayerdetailsGet(playerid);
          setplayername(response?.data?.data?.firstName)
        } catch (error) {
        return;
        }
      };



     

      
            
          useEffect(() => {
            const createHubConnection = async () => {
                const hubConnect = new signalR.HubConnectionBuilder()
                    .withUrl(REACTAPPSignalRPREFIX,{
                      skipNegotiation: true,
                      transport: signalR.HttpTransportType.WebSockets,
                    })
                    .withAutomaticReconnect()
                    .build();
                try {
                    await hubConnect.start();
                   
                } catch (err) {
                  
                    return;
                }
                setHubConnectionnotification(hubConnect);
            }
            createHubConnection();
          }, []);
          
          useEffect(() => {
            if (hubConnectionnotification) {
              hubConnectionnotification.on('PushNotificationToUser', (data) => {
                setNotificationget((prevNotifications) => {
                    const combinedNotifications = [data, ...prevNotifications];
                    const uniqueNotifications = [...new Set(combinedNotifications?.map(JSON.stringify))]?.map(JSON.parse);
                    
                    const  uniqueNotificationsreadlength = uniqueNotifications?.filter((notification) => !notification?.isViewed && !notification?.isRead)?.length;
                    setNotificationlength(uniqueNotificationsreadlength);
                    return uniqueNotifications;
                  });
           
              });
          }
          }, [hubConnectionnotification]); 

     
          useEffect(() => {
            const createHubConnection = async () => {
           const hubConnect = new signalR.HubConnectionBuilder()
            .withUrl(REACTAPPSignalRPREFIX, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          accessTokenFactory: () => Cookies.get("token")
        })
        .withAutomaticReconnect()
        .build();
      try {
        await hubConnect.start();
       
        hubConnect.onclose(x=>{
          hubConnect.start();
          });
      } catch (err) { 
      return;
       
      }
      setHubConnectionnotificationbell(hubConnect);
    };
    createHubConnection();
  }, []);

  useEffect(() => {
    if (hubConnectionnotificationbell) {
      hubConnectionnotificationbell.on('SendNotificationToSpecificUser', (userid,sender,message) => {
        if(message){
             if(message?.includes("wants")||message?.includes("accepted")||message?.includes("has"))
          {
            notificationGetcall(userid)
          }
          else{
            leagueinvitecall(userid)
      
          }
      }
      else{
        return;
      }
      });
    }
  }, [hubConnectionnotificationbell]);
       

  const WatchlistFun = async (userid) => {
    try {
      const response = await WatchlistmoviesGet(userid); // You might want to pass some parameters to Dashboardget
      setWatchlistlength(response?.data?.data);
      dispatch(setWatchlistcount(response?.data?.data?.length))
      
    } catch (error) {
      if(error?.message===errorstatuscheck){         
        await Errormsg(reloginMsg);
        await handle401Error(cookieNames)
        await Navigate("/");
      }
      
    }
  };
          useEffect(() => {
            if(userid){
              WatchlistFun(userid);
            }
              }, [userid]);
       



const refreshTokensend = async() => {
  var bodyFormData = new FormData();
 bodyFormData.append("refreshToken",Cookies.get("rrefreshToken"));
 bodyFormData.append("tokens",Cookies.get("token"));  
 try { 
   const response =  await refeshTokenapi(bodyFormData);
   if (response?.status === 200) {
    await Cookies.set('token',response?.data?.token)
    await Cookies.set('rrefreshToken',response?.data?.newRefreshToken)
    }             
 } catch (error) {
  return;
 }

}; 


useEffect(() => {
  if (Cookies.get("token") != null && Cookies.get("token") != undefined) {
    const interval = setInterval(() => {
      refreshTokensend(); //run refresh token API call after user login 
    }, 3000000);
    return () => {
      clearInterval(interval);
    };
  }
}, []);

const checktheToken = async () => {
  try {
    const response = await confirmemailGet();// You might want to pass some parameters to get the token expire or not
    if(response?.status===200 && response?.data==emailconfirmtokenAuthcheck)
      {
        return;
      }
  } catch (error) {
    if(error?.message===errorstatuscheck400 && error?.response?.data==tokenAuthcheck){ 
      refreshTokensend();     
    }

  }
};

useEffect(() => {
  if(userid!=null||undefined)
  checktheToken();
    }, [userid]);

 
  return (
    <Navbar
      expand="lg"
      data-bs-theme="dark"
      className="bg-body-tertiary navbar_main position-fixed top-0 z-3 ">
      <div className="containers con_div">
        <div className="nav_items">
        
          <Navbar.Brand>
            <img className="navlogo" src={logo} alt="img" onClick={()=>Navigate('/')}/> <b className="navlogo-text" onClick={()=>Navigate('/')}>Gaffer</b><sup className="Trademark2">TM</sup>
          </Navbar.Brand>       
          <Offcanvas show={show} onHide={handleClose}  scroll= {true}
            placement={"end"} 
   >
        <Offcanvas.Header closeButton className="close_btn_offcanvas">
          
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas_body">
        <div  className="side-nav-bar-links">
<div className="navdropdown">
 <Link to="/" className={`movieslink ${location.pathname  === '/' ? 'is_active' : ''}`} >
   <Nav.Link href="homeindex" >Home</Nav.Link>
 </Link>
</div>
<div className="navdropdown">
 <Link to="/Howitwork" className={`movieslink ${location.pathname  === '/Howitwork' ? 'is_active' : ''}`}  >
   <Nav.Link href="howitwork">How it Works</Nav.Link>
 </Link>
</div>
<div className="navdropdown">
 <Link to="/Movieslist" className={`movieslink ${isActiveformovies ? 'is_active' : ''}`}  >
   <Nav.Link href="movieslist">Movies</Nav.Link>
 </Link>
</div>
<div className="navdropdown">
 <Link to="/Newslisting" className={`movieslink ${isActivefornews ? 'is_active' : ''}`} >
   <Nav.Link href="newslisting">News</Nav.Link>
 </Link>
</div>

<div className="navdropdown">
 <Link to="/Contactuspage" className={`movieslink ${location.pathname  === '/Contactuspage' ? 'is_active' : ''}`} >
   <Nav.Link href="contactuspage">Contact Us</Nav.Link>
 </Link>
</div>
<div className="navdropdown">
 <Link to="/Aboutus" className={`movieslink ${location.pathname  === '/Aboutus' ? 'is_active' : ''}`} >
   <Nav.Link href="Aboutus">About Us</Nav.Link>
 </Link>
</div>
{ name ? (
<div className="navdropdown">
   <Link to="/Dashboard" className={`movieslink ${['/Dashboard', '/Editprofile', '/Myleagues', '/Createlegue' ,'/Changepassword','/Watchlist','/Subscriptionpayment','/editprofile','/Myleaguedlive','/Myleagueupcoming','/Myleagueinvites','/Myleaguehistory','/myleagues','/viewdetails','/Draft'].some(path => location?.pathname == path) || location.pathname.startsWith('/Draft')|| location.pathname.startsWith('/viewdetails') ? 'is_active' : ''}`}>
   <Nav.Link href="Dashboard">Dashboard</Nav.Link>
 </Link> 
</div>
   ) : null}
</div>
        </Offcanvas.Body>
      </Offcanvas>
<div>
  
</div>
  <div className="navbar-content ">
    <div className="nav-content-itmes">
  <div className="navdropdown">
 <Link to="/" className={`movieslink ${location?.pathname  === '/' ? 'is_active' : ''}`} >
   <Nav.Link href="homeindex">Home</Nav.Link>
 </Link>
</div>
<div className="navdropdown">
 <Link to="/Howitwork" className={`movieslink ${location?.pathname  === '/Howitwork' ? 'is_active' : ''}`} >
   <Nav.Link href="howitwork"  >How it Works</Nav.Link>
 </Link>
</div>
<div className="navdropdown">
 <Link to="/Movieslist"  className={`movieslink ${isActiveformovies? 'is_active' : ''}`} >
   <Nav.Link href="movieslist">Movies</Nav.Link>
 </Link>
</div>
<div className="navdropdown">
 <Link  to="/Newslisting" className={`movieslink ${isActivefornews ? 'is_active' : ''}`}>
   <Nav.Link href="newslisting" >News</Nav.Link>
 </Link>
</div>

<div className="navdropdown">
 <Link to="/Contactuspage"  className={`movieslink ${location?.pathname  === '/Contactuspage' ? 'is_active' : ''}`} >
   <Nav.Link href="contactuspage">Contact Us</Nav.Link>
 </Link>
</div>
<div className="navdropdown">
 <Link to="/Aboutus" className={`movieslink ${location?.pathname  === '/Aboutus' ? 'is_active' : ''}`}  >
   <Nav.Link href="Aboutus" >About Us</Nav.Link>
 </Link>
</div>
{ name ? (
<div className="navdropdown">
   <Link to="/Dashboard" className={`movieslink ${['/Dashboard', '/Editprofile', '/Myleagues', '/Createlegue' ,'/Changepassword','/Watchlist','/Subscriptionpayment','/editprofile','/Myleaguedlive','/Myleagueupcoming','/Myleagueinvites','/Myleaguehistory','/myleagues','/viewdetails','/Draft'].some(path => location?.pathname == path) || location.pathname.startsWith('/Draft')|| location.pathname.startsWith('/viewdetails')  ? 'is_active' : ''}`}>
   <Nav.Link href="Dashboard">Dashboard</Nav.Link>
 </Link> 
</div>


   ) : null}
</div>
             <div className="search-bar d-flex align-items-center">    
            { name ? null : (
            <div className="col">
            <div  className="ei-user" onClick={handleOpen}>
            <YourIcon />
             </div>
             
           
        <Reuseformodal openforgot={openforgot} setOpenforgot={setOpenforgot} toggleform={toggleform} setToggleform={setToggleform}showotp={showotp}setShowotp={setShowotp}  closeModal={closeModal}   />
            </div>
         )}
          { name ? (
          <div className="dash-alertic">
          <ModalTrading    setNotificationget={setNotificationget} setNotificationlength={setNotificationlength} playername={playername}showModalTrading={showModalTrading} setShowModalTrading={setShowModalTrading} trademovies={trademovies}  setTrademovies={setTrademovies} notificationlength={notificationlength}  showtradeaccpet={showtradeaccpet} setShowTradeaccpet={setShowTradeaccpet}/>
          <ModalTradeMoviedetails />
          <div className="alert-icon">
            <div className="watchlist-icon">
          
          <a onClick={()=>Navigate("/Watchlist")}>Watchlist</a>
          {watchlistcount<=0||null ||undefined?null:<span>
                {watchlistcount<=0||null ||undefined?null:watchlistcount}
              </span>}
            </div>
          <span className="d-flex position-relative ">
                      <YourIconn className="dropdown-toggle notification_icon"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-expanded="true"/>
                       
                  {notificationlength < 1 ? null : (
  <span className="badge">{notificationlength}</span>
)}

{notificationget?.length > 0 ? (
  <div className="dropdown-menu notification-dropmenu" aria-labelledby="dropdownMenuButton">
    {notificationget?.map((data, index) => (
       <div
        className="notificationitem_div dropdown-item notifiction_item"
        onClick={() => openModalTrading(data?.tradeMessage||data?.messages||data,data?.id)}
        key={index}
      >
        <a className="dropdown-item notifiction_item">
{data?.tradeMessage?.includes("wants") ? 
  data?.tradeMessage?.slice(0, data?.tradeMessage?.indexOf("wants"))?.trim ()?.charAt (0)?.toUpperCase () + 
  data?.tradeMessage?.slice(0, data?.tradeMessage?.indexOf("wants"))?.trim ()?.slice (1) + 
  " has requested a trade" : 
  (data?.tradeMessage?.includes("accepted") ? 
    data?.tradeMessage?.slice(0, data?.tradeMessage?.indexOf("accepted"))?.trim ()?.charAt (0)?.toUpperCase () + 
    data?.tradeMessage?.slice(0, data?.tradeMessage?.indexOf("accepted"))?.trim ()?.slice (1) + 
    " accepted your trade request." : 
    (data?.tradeMessage?.includes("has") ? 
      data?.tradeMessage?.slice(0, data?.tradeMessage?.indexOf("has")) ?.trim ()?.charAt (0)?.toUpperCase () + 
      data?.tradeMessage?.slice(0, data?.tradeMessage?.indexOf("has")) ?.trim ()?.slice (1) + 
      " has rejected your trade request." : 
      data?.messages||data))}
          {(data?.isViewed==false || data?.isRead
==false) && <button className="new-btn">New</button>}
        </a>
      </div>
    )) }



  </div>
 ) : null} 

                      
                      </span>
               <img
              src={(loginuserinfo?.userprofileimage==undefined?imgpath+userprofileimage:imgpath+loginuserinfo?.userprofileimage)}
              className="profileicon"
              href="##"
              alt="proimg"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = profileimg }}
            />  
            <div className="d-flex align-items-center name_with_btn">
           <div className="user_name">
           {
  (loginuserinfo?.name?.length > 10 || name?.length > 10)
    ? (
      loginuserinfo?.name
        ? loginuserinfo?.name?.substring(0, 1).toUpperCase() + loginuserinfo?.name?.substring(1, 10) + '..'
        : name?.substring(0, 1).toUpperCase() + name?.substring(1, 10) + '..'
      )
    : (
      loginuserinfo?.name
        ? loginuserinfo?.name.charAt(0).toUpperCase() + loginuserinfo?.name.slice(1)
        : name.charAt(0).toUpperCase() + name.slice(1)
      )
}
           
            </div>
             <div className="icon_arrow">
  <div  className="dropdown-togglee" type="button" id="dropdownMenu1" data-toggle="dropdown" >
  <YourIconarrow  />
  </div>
  <ul className="dropdown-menu arrow_dropmenu" aria-labelledby="dropdownMenu1">
    <li className="dropdown-hover">
    <a
          className="arrow_link" 
          onClick={()=>navigate("/Editprofile")}
        >
          EDIT PROFILE
        </a>
    </li>
    <li className="dropdown-hover">
<a
      className="arrow_link"
      onClick={()=>navigate("/Changepassword")}
    >
      CHANGE PASSWORD
    </a>
</li >
<li className="dropdown-hover">
<a
      className="arrow_link"
      onClick={()=>logOut()}
    >
      LOGOUT
    </a>
</li>
  </ul>
</div>         
</div>  
          </div>
        </div>
        ) : null }
</div>
  </div>
  <button className="nav_slider_btn"  onClick={handleShow}>
          <i className="fa-solid fa-bars"></i>
            </button> 
        </div>
      </div>
    </Navbar>
  );
}

export default Navbarr;
