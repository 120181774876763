
import React, { useState, useRef,useEffect } from "react";
import ScrollButton from "../../components/scrollbutton/ScrollButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { UseCrealeleagueformvalidation } from "../createleague/UseCrealeleagueformvalidation";
import "../createleague/Createlegue.css";
import swal from "sweetalert";
import Subheader from "../../components/subheader/Subheader";
import { Footer } from "../../components/footer/footer";
import Submitbtn from "../../components/submit/Submitbtn";
import Navbarr from "../../components/header/Navbarr";
import { ReactComponent as YourIconarrow } from "../../assets/keyboard_arrow_down.svg";
import { ReactComponent as YourIconclose} from "../../assets/xmark-solid (1).svg";
import { v4 as uuidv4 } from 'uuid';
import dayjs from "dayjs";
import { templatedetailsGet,alltemplatedetailsGet, updateleaguePost, leaguedetailsGet, categoryByidget} from "../../api/handleapi";
import { useNavigate } from "react-router-dom";
import { Errormsg } from "../../components/tostifymsg/Errrormsg";
import Cookies from 'js-cookie';
import { useCategory } from "../../components/useCategory";
import Loader from "../../components/loader/Loader";
import {  errorstatuscheck, leaguealreadyMsg, leaguestatuscheck, leagueupdatedMSG, reloginMsg, successfullyTitle, WrongTitle } from "../../components/language/Language";
import { cookieNames } from "../../Config/config";
import { handle401Error } from "../../components/handle401Error";
import Googleadshorizontal from "../../components/google-AdSense/Googleadshorizontal";
import { useDispatch } from "react-redux";
import { setUpdateleagueinfo } from '../../feature/showSlice/updateleagueinfoSlice';

function Updateleague() {
   const updateleagueid= Cookies.get("updateleagueid")
 const[ rcrcheck,setRcrcheck]=useState(null);
    const [wildcardvalue,setWildcardvalue]=useState();
    const [benchvalue,setBenchvalue]=useState();
   const Navigate=useNavigate();
   const userid=Cookies.get("id")
   const dispatch=useDispatch();
   const [loading,setLoading]=useState(false);
   const [categdetails,setcategdetails]=useState([]);
   const [leaguedetails, setLeaguedetails] = useState([])
   const yesterday = dayjs().subtract(0, 'day');
   const [showdrpdown,setShowdrpdown] = useState(false);
   const [showclose,setShowclose] = useState(false);
     const [selectedoption, setSelectedoption] = useState([]);
     const [alltemplatedetails, setAlltemplatedetails] = useState([]);

     const [cleague, setCleague] = useState({
       LeagueName: "",
       Entries: "",
       Visibility: "",
       StartDateTime: "",
       EndDateTime: "",
       SelectedCategoryIds: [],
       Imagesfile: "",
       TemplateTypeId: "",
       CreatedById:userid,
       Description: "",
       IsActive: "true",
     });

    
      
     const leaguedFun = async (updateleagueid) => {
        try {
          const response = await leaguedetailsGet(updateleagueid); 
          setLeaguedetails(response?.data?.data);
          setCleague({
       LeagueName: response?.data?.data?.leagueName,
       Entries: response?.data?.data?.entries,
       Visibility: response?.data?.data?.visibility=="Private"?1:0,
       StartDateTime: dayjs(response?.data?.data?.startDateTime),
       EndDateTime: dayjs(response?.data?.data?.endDateTime),
       SelectedCategoryIds: [],
       Imagesfile:  "",
       TemplateTypeId: response?.data?.data?.templateTypeId,
       CreatedById:userid,
       Description: response?.data?.data?.description,
       IsActive: "true",
          })
          setcategdetails([response?.data?.data?.category][0]?.split(',')?.map(Number))
          dispatch(setUpdateleagueinfo({leagueimage:response?.data?.data?.image}))
 if(response?.data?.data?.templateTypeId==8){
  setRcrcheck(response?.data?.data?.templateTypeId)
 }
 else{
  setRcrcheck(null)
 }
        } catch (error) {
          if(error?.message==errorstatuscheck){         
            await Errormsg(reloginMsg);
            await handle401Error(cookieNames)
            await Navigate("/");
          }
          
        }
      };

  useEffect(() => {
    const categoryFun = async () => {
      try {
        const categoryNames = [];
        const categoryIds = categdetails?.map(id => id?.toString());
  
        for (const id of categoryIds) {
            let idd = uuidv4();
          const response = await categoryByidget(id);
          categoryNames.push({ idd,id:parseInt(id), category: response?.data.data?.category });
        }
     
        setSelectedoption(categoryNames?.filter((item) => item?.id != 0));
      } catch (error) {
        await Errormsg(reloginMsg);
        await handle401Error(cookieNames)
        await Navigate("/");
      }
    };
    categoryFun();
  }, [categdetails]);
        
    
     useEffect(() => {
        leaguedFun(updateleagueid);
          }, []);
    
   
     const CategoryoptionsCleague=useCategory();
     let extractedValue=[];
     let arr = selectedoption;
     cleague.SelectedCategoryIds = extractedValue;
   
     for (let i = 0; i < arr.length; ++i) {
       extractedValue.push(arr[i].id);
     }
     const moment = require('moment-timezone');
    //  const startdatetime = new Date(cleague?.StartDateTime).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
    //  const enddatetime = new Date(cleague?.EndDateTime).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
     const startdatetime = new Date(cleague?.StartDateTime).toLocaleString(undefined, { timeZone: 'utc' });  // this startdatetime used for UAT environment
     const enddatetime = new Date(cleague?.EndDateTime).toLocaleString(undefined, { timeZone: 'utc' });     // this EndDateTime used for UAT environment
   
   
   const alltemplateGet = async () => {
     try {
       const response = await alltemplatedetailsGet(); // You might want to pass some parameters to alltemplatedetails get
       setAlltemplatedetails(response?.data?.data);
     } catch (error) {
       return;
     }
   };
   useEffect(() => {
     alltemplateGet();
       },[]);
  
   
   const handleLeague = async () => {
       setLoading(true);
       var bodyFormData = new FormData();
       bodyFormData.append("LeagueName", cleague?.LeagueName);
       bodyFormData.append("Entries", cleague?.Entries);
       bodyFormData.append("StartDateTime", startdatetime);
       bodyFormData.append("EndDateTime", enddatetime);
       bodyFormData.append("TemplateTypeId", cleague?.TemplateTypeId);
       bodyFormData.append("CreatedById", cleague?.CreatedById);
       bodyFormData.append("SelectedCategoryIds", cleague?.SelectedCategoryIds);
       bodyFormData.append("Description", cleague?.Description);
       bodyFormData.append("Imagesfile", cleague?.Imagesfile);
       bodyFormData.append("Visibility", cleague?.Visibility);
     
     try {
       const response = await updateleaguePost(Cookies.get("updateleagueid"),bodyFormData);
       if (response?.status === 200) {
         setLoading(false);
         Cookies.set("cleagueid",response?.data?.data?.id);
         Cookies.remove("updateleagueid")
         if(cleague?.Visibility==1){
           await swal({
             title: successfullyTitle,
             text: leagueupdatedMSG,
             icon: "success",
             buttons: "My Upcoming leagues"  ,
             closeOnClickOutside:false,
             dangerMode: false,
           }).then((value) => {
              setRcrcheck(null)
              Navigate("/Myleagueupcoming");
           });
         }
         else{
           await swal({
             title: successfullyTitle,
             text: leagueupdatedMSG,
             icon: "success",
             buttons: "My Upcoming leagues"  ,
             closeOnClickOutside:false,
             dangerMode: false,
           }).then((value) => {
            setRcrcheck(null)
             Navigate("/Myleagueupcoming");
            
           });
         }
         
       } 
       } catch (error) {
       if (error.response) {
         setLoading(false);
         if(error?.response?.data?.message==leaguestatuscheck){
           await swal({
             title: WrongTitle,
             text: leaguealreadyMsg,
             icon: "error",
             buttons: "Ok"  ,
             closeOnClickOutside:false,
             dangerMode: true,
           })
         }
         else{
            return;
         }
         // The request was made and the server responded with a status code
       }
     }
       
     };
   
    
    const templateFun = async (id) => {
     try {
       const response = await templatedetailsGet(id); // You might want to pass some parameters to template get
       setWildcardvalue(response?.data?.data?.wildCard)
       setBenchvalue(response?.data?.data?.bench)
     } catch (error) {
       if(error?.message==errorstatuscheck){         
         await Errormsg(reloginMsg);
         await handle401Error(cookieNames)
         await Navigate("/");
       }
     }
   };
   
   
   const wildCardId = CategoryoptionsCleague?.find(item => item?.category == "Wild Card")?.id;
   const benchId = CategoryoptionsCleague?.find(item => item?.category == "Bench")?.id;
   
   for (let i = 0; i < wildcardvalue; i++) {
     cleague?.SelectedCategoryIds?.push(wildCardId);
   }
   
   for (let i = 0; i < benchvalue; i++) {
     cleague?.SelectedCategoryIds?.push(benchId);
   }
   
     const handleRCRTemplate = () => {
       const { isValid } = validateForm({
         cleague,
         errors,
         forceTouchErrors: true,
       });
     
       if (isValid) {
         return;
      
       } else {
         // Handle the case when isValid is false, if needed
         return;
       }
     };
     
     const onSubmitForm = async(e) => {
       e.preventDefault();
       const { isValid } = validateForm({
         cleague,
         errors,
         forceTouchErrors: true,
       });
     
       if (cleague?.TemplateTypeId == "8") {
         // Handle RCR Template logic here     
         await handleRCRTemplate();
       } else if (cleague?.TemplateTypeId == "3") {
         // Handle Custom Template logic here
         if (!isValid) {
           return swal({
             title: "Wrong",
             text: "Fill the Details",
             icon: "error",
             buttons: "ok",
             closeOnClickOutside:false,
             dangerMode: true,
           });
         }
   
         }
        
         await handleLeague();
     };
     
     const fileInputRef = useRef(null);
   
     const handleButtonClick = () => {
       fileInputRef.current.click();
     };
   
     
     const handleFileChange = (event) => {
       const file = event.target.files[0];
       if (file) {
         // Update the state with the selected file
         setCleague({ ...cleague, Imagesfile: file });
     
         // Perform validation only if the file is selected and there are existing errors
         if (errors.Imagesfile?.dirty) {
           validateForm({
             cleague: { ...cleague, Imagesfile: file}, // Pass the updated state with the file
             errors,
             field: "Imagesfile",
           });
         }
       }
     };
   
   
     const { errors, validateForm, onBlurField } =
       UseCrealeleagueformvalidation(cleague);
   
     const onUpdateField = async(e, value) => {
       const field = e.target.name;
       const nextFormState = { ...cleague, [field]: e.target.value };
        if(nextFormState?.TemplateTypeId==8 && rcrcheck==null)

         {
                await setSelectedoption([]);
                await setCleague({SelectedCategoryIds:[]})
                await templateFun(8)
             }
             else if(nextFormState?.TemplateTypeId==8 && rcrcheck!=null){
              setCleague(nextFormState);
              if (errors[field].dirty)
                validateForm({
                  cleague: nextFormState,
                  errors,
                  field,
                });
              }
                else if(nextFormState?.TemplateTypeId!=8){
                  if(rcrcheck!=null){
                await setSelectedoption([]);
                await setCleague({SelectedCategoryIds:[]})  
                setCleague(nextFormState);
                if (errors[field].dirty)
                  validateForm({
                    cleague: nextFormState,
                    errors,
                    field,
                  });  
                  }
               else{
                setCleague(nextFormState);
                if (errors[field].dirty)
                  validateForm({
                    cleague: nextFormState,
                    errors,
                    field,
                  }); 
               }
                }
           
       setCleague(nextFormState);
       if (errors[field].dirty)
         validateForm({
           cleague: nextFormState,
           errors,
           field,
         });  
     };
     const onUpdateFieldd = async(e, value) => {
      const field = e.target.name;
      const nextFormState = { ...cleague, [field]: e.target.value };         
      setCleague(nextFormState);
      if (errors[field].dirty)
        validateForm({
          cleague: nextFormState,
          errors,
          field,
        });  
    };
     const onUpdateFielddescription = (e, value) => {
       const field = e.target.name;
       const nextFormState = { ...cleague, [field]: e.target.value };
       setCleague(nextFormState);
       if (errors[field].dirty)
         validateForm({
           cleague: nextFormState,
           errors,
           field,
         });  
     };

   
     const onUpdateFieldstartdob = (StartDateTime) => {
       const nextFormState = {
         ...cleague,
         StartDateTime: StartDateTime,
       };
       setCleague(nextFormState);
       if (errors.StartDateTime?.dirty) {
         validateForm({
           cleague: nextFormState,
           errors,
           field: "StartDateTime",
         });
       }
     };
   
     const onUpdateFieldenddob = (EndDateTime) => {
       const nextFormState = {
         ...cleague,
         EndDateTime: EndDateTime,
       };
       setCleague(nextFormState);
       if (errors.EndDateTime?.dirty) {
         validateForm({
           cleague: nextFormState,
           errors,
           field: "EndDateTime",
         });
       }
     };
   
      
   const handleSelectChange = (e, { category, id , index}) => {
     const field = "SelectedCategoryIds"; 
     e.target.checked = true;
     let idd = uuidv4();
     const newOption = {idd, category, id };
   if(selectedoption?.length>= 15){
     setShowdrpdown(false)
     return;
   }
     setSelectedoption((selectedoption) => [...selectedoption, newOption ]);
     setShowdrpdown(false);
       if (errors[field].dirty) {
           validateForm({
             cleague: { ...cleague, [field]: newOption },
             errors,
             field,
           });
         }
   };
   
   
   const handleClear = (item) => {
         let filteredArray = selectedoption?.filter((el,i)=>{ return el?.idd !== item?.idd });
         setSelectedoption(filteredArray);
   };
   
   const dropdownCheck=(val)=>{
     if(selectedoption?.length >= 15){
       setShowdrpdown(false)
       return;
     }
     setShowdrpdown(val);
   }

  return (
    <div>
    <Navbarr/>
    <div className="after-nav containers con_div">
    <Googleadshorizontal props="mt-2 mb-2 text-center"/>
      <Subheader />
      <br />
      <div className="card cleague-main">
        <nav className="navbar   cleague-nav">
          <span className="navbar-brand mb- h1 cleague">Update League</span>
        </nav>
        <br />
        <div className="form-createl">
          <div className="row g-3 ">
            <div className="col-sm-5">
              <label for="exampleFormControlInput1" className="form-label">
                League Name
              </label>
              <input
                type="text"
                autocomplete="off"
                name="LeagueName"
                value={cleague?.LeagueName}
                className="form-control  inputf-control   errors.LeagueName.dirty 
                errors.LeagueName.error 
               formFieldError"
                placeholder="League Name"
                aria-label="City"
                onChange={onUpdateFieldd}
                onBlur={onBlurField}
              />
              {errors.LeagueName.dirty && errors.LeagueName.error ? (
                <p className="c_leagueFieldErrorMessage">
                  {errors.LeagueName.message}
                </p>
              ) : null}
            </div>
            <div className="col-sm">
              <label for="exampleFormControlInput1" className="form-label">
                Players <i className="fa-solid fa-circle-exclamation"></i>
              </label>
              <input
                type="number"
                name="Entries"
                className="form-control  inputf-control"
                placeholder="Players"
                aria-label="State"
                value={cleague?.Entries}
                id="tentacles"
                min="2"
                max="10"
                onChange={onUpdateFieldd}
                onBlur={onBlurField}
              />
                {errors.Entries.dirty && errors.Entries.error ? (
                <p className="c_leagueFieldErrorMessage">
                  {errors.Entries.message}
                </p>
              ) : null}
            </div>
            <div className="col-sm">
              <label for="exampleFormControlInput1" className="form-label">
                {" "}
                League Visibility
              </label>
              <div className="input-group">
                <select
                  type="text"
                  className="form-select inputf-control"
                  id="inputGroupSelect04"
                  value={cleague?.Visibility}
                  name="Visibility"
                  aria-label="Example select with button addon"
                  onChange={onUpdateFieldd}
                  onBlur={onBlurField} 
                >
                  <option selected>Select visibility</option>
                  <option value="1">Private</option>
                  <option value="0">Public</option>
                </select>
              </div>
              {errors.Visibility.dirty && errors.Visibility.error ? (
                <p className="c_leagueFieldErrorMessage">
                  {errors.Visibility.message}
                </p>
              ) : null}
            </div>
          </div>
          <br/>
          <div className="row g-3">
            <div className="col-sm-5 input-container">
              <label for="exampleFormControlInput1" className="form-label">
                Upload the League Picture
              </label>
              <input
                type="file"
                 autocomplete="off"
                accept="image/png, image/jpeg"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <input
                value={cleague.Imagesfile ? cleague.Imagesfile.name : ""}
                name="Imagesfile"
                 autocomplete="off"
                className="form-control inputf-controlupload"
                placeholder="Upload image"
                aria-label="img_upload"
              />
              <button className="img-upload-btn" onClick={handleButtonClick}>
                Upload
              </button>
              {errors.Imagesfile.dirty && errors.Imagesfile.error ? (
                <p className="c_leagueFieldErrorMessage">
                  {errors.Imagesfile.message}
                </p>
              ) : null}
            </div>
            <div className="col-sm">
              <div>
                <label for="exampleFormControlInput1" className="form-label">
                  Draft Start Date & Time
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
               
                    className="w-100"
                    name="StartDateTime"
                    defaultValue={yesterday}
                    disablePast
                    value={cleague?.StartDateTime}
                    onChange={onUpdateFieldstartdob}
                    onBlur={onBlurField}
                    closeOnSelect={false}
                    error
                    sx={{
                      ".MuiOutlinedInput-root": {
                        color: "#e6e6e6 !important",
                        borderColor: "#286ac0",
                        borderRadius: "5px",
                      },
                      ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#286ac0 !important",
                          borderRadius: "5px !important",
                        },

                      ".MuiButtonBase-root": {
                        color: "#286ac0 !important",
                      },
                    }}
                  />
                </LocalizationProvider>
                {errors.StartDateTime.dirty && errors.StartDateTime.error ? (
                <p className="c_leagueFieldErrorMessage">
                  {errors.StartDateTime.message}
                </p>
              ) : null}
              </div>
            </div>
            <div className="col-sm">
              <div>
                <label for="exampleFormControlInput1" className="form-label">
                 League End Date & Time
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    className="w-100"
                    name="EndDateTime"
                    value={cleague?.EndDateTime}
                    defaultValue={yesterday}
                    disablePast
                    onChange={onUpdateFieldenddob}
                    onBlur={onBlurField}
                    closeOnSelect={false}

                    error
                    sx={{
                      ".MuiOutlinedInput-root": {
                        color: "#e6e6e6 !important",
                        borderColor: "#286ac0",
                        borderRadius: "5px",
                      },
                      ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#286ac0 !important",
                          borderRadius: "5px !important",
                        },

                      ".MuiButtonBase-root": {
                        color: "#286ac0 !important",
                      },
                      
                    }}
                    minDate={
                      cleague?.StartDateTime ? dayjs(cleague?.StartDateTime).add(1, 'day') : yesterday
                      
                    } 
                   
                  />
                </LocalizationProvider>
                {errors.EndDateTime.dirty && errors.EndDateTime.error ? (
                <p className="c_leagueFieldErrorMessage">
                  {errors.EndDateTime.message}
                </p>
              ) : null}
              </div>
            </div>
          </div>
          <br />
          <div className="row g-3">
            <div className="col-sm-5">
              <label for="exampleFormControlInput1" className="form-label">
                Draft Template
              </label>
              <div className="input-group ">
                <select
                  type="text"
                  className="form-select draft-input-group"
                  name="TemplateTypeId"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  value={cleague?.TemplateTypeId}
                  onChange={onUpdateField}
                  onBlur={onBlurField}
                >
                  <option selected >Select Template</option>
                  {alltemplatedetails?.map((item,index)=>(
                   
                    <option value={item?.id} key={index}>{item?.templateName}</option>
                 
                  ))}

                </select>
             
              </div>
              {errors.TemplateTypeId.dirty && errors.TemplateTypeId.error ? (
                <p className="c_leagueFieldErrorMessage">
                  {errors.TemplateTypeId.message}
                </p>
              ) : null}
            </div>
            {cleague?.TemplateTypeId == "8" || cleague?.TemplateTypeId === "" ?  "" :  (
              <div className="col-sm-7">
                <label for="exampleFormControlInput1" className="form-label">
                  Select the Category
                </label>
                <div className="input-group slec-genre">
<div className="custom-preview" onClick={()=>{dropdownCheck(!showdrpdown)}}>
{selectedoption.length === 0 ? 
<div>
  <p  className="custom-arrow">{showdrpdown ?"": 
<YourIconarrow />} </p>
  <p  className="custom-arrow">{showclose ? <YourIconclose className="close_icon"/> :  ""}</p>
</div>
: 
selectedoption?.map((item,index)=>{
      return(
              <div className="custom-displaySelection" key={index}  >
                {item?.category}
                <span className="cross" onClick={() =>handleClear(item)}>X</span>
                </div>
                       )
  })
}
</div>
                          {errors.SelectedCategoryIds.dirty && errors.SelectedCategoryIds.error ? (
                <p className="c_leagueFieldErrorMessage">
                  {errors.SelectedCategoryIds.message}
                </p>
              ) : null}    
{
showdrpdown ? 
<ul className="custom-dropdown" id="custom-dropdownlist">
{ 
CategoryoptionsCleague?.sort((a, b) => a?.category?.localeCompare(b?.category))?.map(({ category, id }, index)=>{
  return(<div>
          <li key={index}>
              <label>
                   <input type="Checkbox" className="custom" value={id} onChange={(e) => handleSelectChange(e,{category, id, index })}>
                   </input>
                   <span id="m-name">{category}</span>
              </label>
          </li>
  </div>)
})
}
</ul> : null 
}             
</div>   
       </div>
            )}
          </div>
          <br />
          <div className="mb-4">
            <label for="exampleFormControlTextarea1" className="form-label">
              League Description
            </label>
            <textarea
              type="text"
            
              className="form-control  inputff-control"
              id="exampleFormControlTextarea1"
              name="Description"
              placeholder="League Description"
              value={cleague?.Description}
              rows="8"
              onChange={onUpdateFielddescription}
              onBlur={onBlurField}
              maxLength={1300}
            ></textarea>
               {errors.Description.dirty && errors.Description.error ? (
                <p className="c_leagueFieldErrorMessage">
                  {errors.Description.message}
                </p>
              ) : null}
          </div>
          <div className="edit_submit_btn" >
            <Submitbtn props={"SUBMIT"} onSubmitForm={onSubmitForm}/>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <ScrollButton />
    {loading?<Loader/>:null}
  </div>
  )
}

export default Updateleague