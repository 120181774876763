import React from "react";
import clsx from "clsx";
import { useState } from "react";
import styles from "./RegisterForm.module.css";
import { UseRegisterinformvalidation } from "./UseRegisterinformvalidation";
import logo from "../../assets/Gaffer Transparent Logo1.png";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {  useDispatch } from 'react-redux';
import { ReactComponent as YourIcon2 } from "../../assets/eye-solid.svg";
import { ReactComponent as YourIcon } from "../../assets/eye-slash-solid.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {registerPost} from "../../api/handleapi";
import Loader from "../../components/loader/Loader";
import { registerSuccessTitle,registerSuccessMsg,emailRegisteredErrorTitle,emailRegisteredErrorMsg, apiErrorMsg, APIerrorTitle,  regAuthcheck, regAuthsuccesscheck, regAuthsuccess } from "../../components/language/Language";
const RegisterForm = ({ setShowModalTwo ,closeModal}) => {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    password: "",
    dob: "",
    confirmPassword: "",
  });
  const [loading,setLoading]=useState(false)
  const dispatch=useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [disableregisterbtn,setDisableregisterbtn]=useState(false);
  const [privacypolicy,setPrivacypolicy]=useState({
    privacypolicychecked:false
  });


  const createPost = async() => {
    try {
      setDisableregisterbtn(true);
      setLoading(true)
      const response =  await registerPost({  firstName: form?.firstName,
        lastName: form?.lastName,
        email: form?.email,
        gender: form?.gender,
        password: form?.password,
        confirmPassword: form?.confirmPassword,
        dob: form?.dob,
        PrivacyPolicy:privacypolicy?.privacypolicychecked
      });

        // check the condition when api response give 200 then also check the message property
      if (response?.status === 200) {
        setLoading(false)
        if (response?.data?.message===regAuthcheck) {
          swal({
            title: emailRegisteredErrorTitle,
            text: emailRegisteredErrorMsg,
            icon: "error",
            buttons: "Login",
            closeOnClickOutside:false,
            dangerMode: true,
          })
          .then(() => {
            setShowModalTwo(false);
            setPrivacypolicy(false);
            setForm({
              firstName: "",
              lastName: "",
              gender: "",
              dob: "",
              email: "",
              password: "",
              confirmPassword: "",
            });
          });
        }
        // check the condition when api response give 200 then also check the message property
         else if(response?.data?.message===regAuthsuccesscheck ||response?.data?.message===regAuthsuccess) {
          setLoading(false)
          swal({
            title: registerSuccessTitle,
            // text: "Check your registered email for verification. \n Check your spam folder if you do not receive the verification email.", 
            text:registerSuccessMsg, 
            icon: "success",
            buttons: "OK",
            closeOnClickOutside:false,
            dangerMode: false,
          }).then(() => {
            setShowModalTwo(false);
            setPrivacypolicy(false);
            setForm({
              firstName: "",
              lastName: "",
              gender: "",
              dob: "",
              email: "",
              password: "",
              confirmPassword: "",
          });
          });
        }
        else{
          swal({
            title: "",
            text: response?.data?.message,
            icon: "success",
            buttons: "OK",
            closeOnClickOutside:false,
            dangerMode: false,
          })
          .then(() => {
            setShowModalTwo(false);
            setPrivacypolicy(false);
            setForm({
              firstName: "",
              lastName: "",
              gender: "",
              dob: "",
              email: "",
              password: "",
              confirmPassword: "",
            });
          });
        }
        }       
      }
    catch (error) {
      setLoading(false)
      if (error.response) {
        swal({
          title:APIerrorTitle,
          text: apiErrorMsg,
          icon: "error",
          buttons: "OK",
          closeOnClickOutside:false,
          dangerMode: true,
        })
        .then(() => {
          setDisableregisterbtn(false);
          setPrivacypolicy(false);
          setForm({
            firstName: "",
            lastName: "",
            gender: "",
            dob: "",
            email: "",
            password: "",
            confirmPassword: "",
          });
        });
        // The request was made and the server responded with a status code
      } 
    }
  };



  const { errors, validateForm, onBlurField } =
    UseRegisterinformvalidation(form);

    
  const onUpdateField = (e) => {
    const field = e.target.name;
    const nextFormState = { ...form, [field]: e.target.value };
    setForm(nextFormState);
    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };
  const onUpdateFielddob = (date) => {
    const nextFormState = { ...form, dob: date };
    setForm(nextFormState);
    if (errors.dob?.dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: "dob",
      });
    }
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    if (!isValid) return;
     createPost();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglecPasswordVisibility = () => {
    setShowcPassword(!showcPassword);
  };

  const closeAll = () => {
        dispatch(closeModal(false));
    setShowModalTwo(false);
    }
   
  return (
<form className={styles.form}>
      <img className={styles.relogo} src={logo} alt="img" />
      <h5 className={styles.create_AC}>
        <b>Create an Account</b>
      </h5>
      <div className="row g-1">
        <div className="col-md-6">
          <div className={styles.formGroup}>
            <input
              className={clsx(
                styles.formField,
                errors.firstName.dirty &&
                  errors.firstName.error &&
                  styles.formFieldError
              )}
              type="text"
              aria-label="fname field"
              name="firstName"
              placeholder="First Name"
              value={form.firstName}
              onChange={onUpdateField}
              onBlur={onBlurField}
            />
            {errors.firstName.dirty && errors.firstName.error ? (
              <p className={styles.formFieldErrorMessage}>
                {errors.firstName.message}
              </p>
            ) : null}
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.formGroup}>
            <input
              className={clsx(
                styles.formField,
                errors.lastName.dirty &&
                  errors.lastName.error &&
                  styles.formFieldError
              )}
              type="text"
              aria-label="lname field"
              name="lastName"
              placeholder="Last Name"
              value={form.lastName}
              onChange={onUpdateField}
              onBlur={onBlurField}
            />
            {errors.lastName.dirty && errors.lastName.error ? (
              <p className={styles.formFieldErrorMessage}>
                {errors.lastName.message}
              </p>
            ) : null}
          </div>
        </div>
      </div>

      <div className={styles.formGroup}>
        <select
          className={clsx("form-select",
            styles.formFieldd,
            errors.gender.dirty && errors.gender.error && styles.formFieldError
          )}
          type="text"
          name="gender"
          aria-label="gender field"
          value={form.gender}
          onChange={onUpdateField}
          onBlur={onBlurField}
        >
          <option value="">Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Prefer Not to Say">Prefer Not to Say</option>
        </select>

        {errors.gender.dirty && errors.gender.error ? (
          <p className={styles.formFieldErrorMessage}>
            {errors.gender.message}
          </p>
        ) : null}
      </div>
   
      <div className={styles.formGroup}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
           
              className="w-100"
              name="dob"
              value={form?.dob}
              label="DOB"
              onChange={onUpdateFielddob}
              onBlur={onBlurField}             
              disableFuture
              error
              sx={{
                ".Mui-error": {
                  color: "#7A7A7A !important",
                  borderColor: "#7A7A7A",
                  borderRadius: "10px",
                },
                ".Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc !important",
                  borderRadius: "10px !important",
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
        {errors.dob.dirty && errors.dob.error ? (
          <p className={styles.formFieldErrorMessage}>{errors.dob.message}</p>
        ) : null}
      </div>
     

      <div className={styles.formGroup}>
        <input
          className={clsx(
            styles.formField,
            errors.email.dirty && errors.email.error && styles.formFieldError
          )}
          type="text"
          autocomplete="off"
          aria-label="Email field"
          name="email"
          placeholder="Email *"
          value={form.email}
          onChange={onUpdateField}
          onBlur={onBlurField}
        />
        {errors.email.dirty && errors.email.error ? (
          <p className={styles.formFieldErrorMessage}>{errors.email.message}</p>
        ) : null}
      </div>

      <div className={styles.formGroup}>
        <input
          className={clsx(
            styles.formField,
            errors.password.dirty &&
              errors.password.error &&
              styles.formFieldError
          )}
          type={showPassword ? "text" : "password"}
          autocomplete="off"
          aria-label="Password field"
          name="password"
          placeholder="Password *"
          value={form.password}
          onChange={onUpdateField}
          onBlur={onBlurField}
        />{" "}
        <div
          onClick={togglePasswordVisibility}
        >
          {showPassword ? (
           <YourIcon className={styles.icon_password_login}/>
          ) : (
            <YourIcon2 className={styles.iicon_password_login}  />
          )}
        </div>
        {errors.password.dirty && errors.password.error ? (
          <p className={styles.formFieldErrorMessage}>
            {errors.password.message}
          </p>
        ) : null}
      </div>

      <div className={styles.formGroup}>
        <input
          className={clsx(
            styles.formField,
            errors.confirmPassword.dirty &&
              errors.confirmPassword.error &&
              styles.formFieldError
          )}
          type={showcPassword ? "text" : "password"}
          aria-label="confirm Password field"
          name="confirmPassword"
          placeholder="Confirm Password *"
          value={form.confirmPassword}
          onChange={onUpdateField}
          onBlur={onBlurField}
        />{" "}
        <div onClick={togglecPasswordVisibility}>
          {showcPassword ? (
            <YourIcon className={styles.icon_password_login}/>
          ) : (
              <YourIcon2 className={styles.iicon_password_login}/>
          )}
        </div>
        {errors.confirmPassword.dirty && errors.confirmPassword.error ? (
          <p className={styles.formFieldErrorMessage}>
            {errors.confirmPassword.message}
          </p>
        ) : null}
      </div>
  
      <div className={styles.formActions}>
        <button
        disabled={disableregisterbtn}
          className={styles.formSubmitBtn}
          type="submit"
          onClick={onSubmitForm}
        >
          Register{" "}
        </button>
      </div>
      <h6 className={styles.logolink}>
        Already have an account?{" "}
        <a className={styles.rr_aa_elem}   onClick={() => setShowModalTwo((prev) => !prev)}>
          Login{" "}
        </a>
      </h6>
      <p className={styles.para_reg}>
        <div className="checkcounter">
        {" "}
        By registering, you agree to Gaffer<sup className="Trademark">TM</sup>{" "}
        <Link to="/Privacypolicy" onClick={closeAll}>
          <a className={styles.r_a_ele} href="/Privacypolicy">
            Privacy Policy
          </a>
        </Link>
        </div>
      </p>
      {loading?<Loader/>:null}
    </form>
  
  
  );
};

export default RegisterForm;
